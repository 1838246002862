import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import {
  faCreditCard,
  faCreditCardAlt,
  faEdit,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcMastercard,
  faCcVisa,
} from '@fortawesome/free-brands-svg-icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import axios from 'axios';
import {
  notificationsAtom,
  tokenAtom,
  userPaymentsAtom,
} from '../../../../../../atoms/Atoms';
import api from '../../../../../../api/api';
import placeToPayApi from '../../../../../../api/placeToPayApi';
import { H3 } from '../../../../../shared-components/typography/Title';
import Subtitle from '../../../../../shared-components/typography/Subtitle';
import Panel from '../../../../../shared-components/panel/Panel';
import Loader from '../../../../../shared-components/loader/Loader';
import envConfig from '../../../../../../envConfig';
import { getPlaceToPayCredentials } from '../../../../../../functions';
import { useMount } from 'react-use';
import PlaceToPayLogo from '../../../../../../assets/images/logo/placetopay-logo-dark.svg';

function Options() {
  const { i18n, t } = useTranslation();
  const token = useRecoilValue(tokenAtom);
  const [payment, setPayment] = useRecoilState(userPaymentsAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [loadingSession, setLoadingSession] = useState(false);
  const [loadingCards, setLoadingCards] = useState(false);
  const [currentIp, setCurrentIp] = useState('');

  const fetchPayment = () => {
    setLoadingCards(true);
    api
      .get('users/me/cards')
      .then((response) => {
        setLoadingCards(false);
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          setPayment({ ...payment, cards: response.data.data.results });
        }
      })
      .catch((error) => {
        setLoadingCards(false);
        console.log(error.message);
      });
  };

  const deletePayment = (id) => {
    api
      .post(`users/cards/remove/${id}`, null)
      .then((response) => {
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description:
              `${t('card_eliminated_error')}`,
              error: true,
            },
          ]);
        } else {
          setNotifications([
            ...notifications,
            {
              title: `${t('yay')}`,
              description:
              `${t('card_eliminated_success')}`,
              error: false,
            },
          ]);
          const array = _.filter(payment.cards, (card) => card.id !== id);
          setPayment({ ...payment, cards: array });
        }
      })
      .catch((error) => {
        setNotifications([
          ...notifications,
          {
            title: `${t('oops')}`,
            description: `${t('card_eliminated_error')} - ${error.message}`,
            error: true,
          },
        ]);
      });
  };

  function handlePlaceToPayResponse(response) {
    if (!window.processing && response.status.status === 'APPROVED') {
      window.processing = true;
      const reference = response.reference;
      const requestId = response.requestId;
      const token = response.signature;
      placeToPayApi.post(`api/session/${requestId}`, {
        auth: getPlaceToPayCredentials()
      })
      .then((detailsResponse) => {
        // setLoadingSession(false);
        if (detailsResponse.data?.subscription?.status.status) {
          const tokenizedCardFields = detailsResponse.data.subscription.instrument;
          const card = { type: 26 };
          for (let i = 0; i < tokenizedCardFields.length; i++) {
            const field = tokenizedCardFields[i];
            if (field.keyword === 'token') {
              card.token = field.value;
            }
            if (field.keyword === 'franchise') {
              card.brand = field.value;
            }
            if (field.keyword === 'issuerName') {
              card.organization = field.value;
            }
            if (field.keyword === 'lastDigits') {
              card.number = field.value;
            }
            if (field.keyword === 'validUntil') {
              const dateFields = field.value.split('-');
              card.expMonth = dateFields[1];
              card.expYear = dateFields[0];
            }
          }
          api.post('users/me/cards', card)
          .then((cardResponse) => {
            window.processing = true;
            setLoadingSession(false);
            if (cardResponse.data.error) {
                setNotifications([
                  ...notifications,
                  {
                    title: `${t('card_not_added')}`,
                    description:
                    `${t('card_error')}`,
                    error: true,
                  },
                ]);
              } else if (cardResponse.data.data) {
                setPayment({
                  ...payment,
                  modal: false,
                  reload: true,
                  cards: [...payment.cards, cardResponse.data.data],
                });
                setNotifications([
                  ...notifications,
                  {
                    title: t('card_added'),
                    description: t('card_success'),
                    error: false,
                  },
                ]);
                fetchPayment();
              }
          })
          .catch((error) => {
            setLoadingSession(false);
            window.processing = true;
            setNotifications([
              ...notifications,
              {
                title: t('card_not_added'),
                description: error.message,
                error: true,
              },
            ]);
          });
        }
        else {
          setLoadingSession(false);
          window.processing = true;
        }
        // console.log(detailsResponse.data.status.status);
      })
      .catch((error) => {
        setLoadingSession(false);
        window.processing = true;
        setNotifications([
          ...notifications,
          {
            title: t('card_not_added'),
            description: error.message,
            error: true,
          },
        ]);
      });
    }
  }

  function getPlaceToPaySessionUrl() {
    getPlaceToPayCredentials();
    setLoadingSession(true);
    placeToPayApi.post('api/session', {
      locale: i18n.language === 'en' ? 'en_US' : 'es_PR',
      auth: getPlaceToPayCredentials(),
      subscription: {
        description: t('save_payment_option_for_later'),
        reference: Math.floor(Math.random() * 1000000)
      },
      returnUrl: window.location.href,
      ipAddress: currentIp,
      userAgent: navigator.userAgent
    })
    .then((response) => {
      setLoadingSession(false);
      if (response.data.status?.status === 'OK') {
        window.P.init(response.data.processUrl);
      } else {
        setNotifications([
          ...notifications,
          {
            title: t('oops'),
            description: t('unknown_error_occurred'),
            error: true,
          },
        ]);
      }
    });
  }

  useEffect(() => {
    if (token) {
      fetchPayment();
    }
  }, [token]);

  useEffect(() => {
    axios.get("https://api.ipify.org/?format=json")
    .then((response) => {
      setCurrentIp(response.data.ip);
    });
    getPlaceToPayCredentials();
  }, []);

  useMount(() => {
    window.P.on('response', handlePlaceToPayResponse);
  });

  return (
    <Panel>
      <H3 text={t('payment_methods')} />
      <Subtitle
        text={t('payment_methods_prompt')}
      />
      <div className="flex items-center space-x-1 text-3xl py-4">
        <FontAwesomeIcon icon={faCcVisa} className="text-blue-600" />
        <FontAwesomeIcon icon={faCcAmex} className="text-cyan-600" />
        <FontAwesomeIcon icon={faCcMastercard} className="text-blue-800" />
        <FontAwesomeIcon icon={faCcDinersClub} className="text-lightBlue-600" />
        <FontAwesomeIcon icon={faCcDiscover} className="text-orange-400" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        <button
          type="button"
          disabled={loadingSession}
          onClick={() => getPlaceToPaySessionUrl()}
          className="text-left text-white transform hover:scale-90 shadow rounded-xl flex h-20 transition duration-500 ease-linear ring-2 ring-offset-2 ring-amber-400 border border-amber-400 outline-none focus:outline-none"
        >
          <div className="w-16 h-full flex flex-shrink-0 items-center justify-center text-white text-3xl bg-amber-400 rounded-l-xl">
            {!loadingSession && (
              <FontAwesomeIcon icon={faCreditCardAlt} />
            )}
            {loadingSession && (
              <Loader color="bg-white" />
            )}
          </div>
          <div className="flex flex-col justify-center p-4 text-xs">
            <div className="font-semibold">{t('add_new_payment')}</div>
            <div>{t('new_card')}</div>
          </div>
        </button>
        {loadingCards && (
          <Loader color="bg-white mt-8 ml-4" />
        )}
        {!loadingCards && _.map(payment.cards, (card) => (
          <div
            key={card.id}
            className={`${card.paymentType === 26 ? '' : 'divide-x divide-purple-200'} flex shadow rounded-xl flex h-20 transition duration-500 ease-linear ring-2 ring-offset-2 ring-purple-400 border border-purple-400 outline-none focus:outline-none`}
          >
            <div className="w-16 flex flex-col items-center justify-center text-white text-3xl bg-purple-600 rounded-l-xl">
              <FontAwesomeIcon icon={faCreditCard} />
            </div>
            <div className="flex flex-col flex-1 justify-center text-xs p-2">
              <div className="font-semibold w-full">{card.brand}</div>
              <div>**** {card.last4}</div>
              <div>{card.expirationDate}</div>
            </div>
            <div className={`${card.paymentType === 26 ? 'divide-x divide-purple-200' : ''} flex`}>
              {card.paymentType === 26 && (
                <img
                  className="w-24 mr-2"
                  alt="Evertec Place To Pay logo"
                  src={PlaceToPayLogo}
                />
              )}
              <div className="flex flex-col items-center w-10">
                <button
                  type="button"
                  onClick={() => {
                    setPayment({ ...payment, edit: card, modal: true });
                  }}
                  className="rounded-tr-xl flex-1 text-purple-600 hover:text-purple-400 px-4 transition duration-500 ease-linear outline-none focus:outline-none ring-0 focus:ring-0"
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button
                  type="button"
                  onClick={() => deletePayment(card.id)}
                  className="rounded-br-xl flex-1 text-purple-600 hover:text-purple-400 px-4 transition duration-500 ease-linear outline-none focus:outline-none ring-0 focus:ring-0"
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Panel>
  );
}

export default Options;
