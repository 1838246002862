import React from "react";
import { motion } from "framer-motion";
import Img1 from '../../../assets/images/photos/frontkick.jpg';
import Img2 from '../../../assets/images/photos/kneepullup.jpg';
import Img3 from '../../../assets/images/photos/cowbell.jpg';
import Img4 from '../../../assets/images/photos/leftpunch.jpg';
import Img5 from '../../../assets/images/photos/mrstrainer.jpg';
import Img6 from '../../../assets/images/photos/stance.jpg';
import Img7 from '../../../assets/images/photos/sidekick.jpg';
import Img8 from '../../../assets/images/photos/trainerright.jpg';
import Img9 from '../../../assets/images/photos/trainersmall.jpg';
import Img10 from '../../../assets/images/photos/rightkick.jpg';

import "./shared-components/ImageGallery.css";
import Logo from "../../shared-components/logo/Logo";

const images = [
  Img1,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  Img7,
  Img8,
  Img9,
  Img10
];

const transition = { duration: 3 };

const Gallery = () => {
  return (
    <div>
      <Logo />
      <div className="image-gallery-container">
        <h1 className="gallery-title my-4">Strength and Endurance</h1>
        <div className="image-gallery">
          {images.map((image, index) => (
            <motion.div
              key={index}
              className="image-container"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={transition}
            >
              <img src={image} alt={`Image ${index + 1}`} />
            </motion.div>
          ))}
       </div>
      </div>
    </div>
  );
};

export default Gallery;
