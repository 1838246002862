import React, { useEffect, useState, useRef } from 'react';
import { useClickAway, useMount } from 'react-use';
import dayjs from 'dayjs';
import { useRecoilState, useRecoilValue } from 'recoil';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { Modal } from 'antd';
import {
  faTimesCircle,
  faCreditCard,
  faCalendar,
  faMapPin,
  faWarehouse,
} from '@fortawesome/free-solid-svg-icons';  
import bookingApi from '../../../api/bookingApi';
import {
  selectedRentalTimeSlot,
  userPaymentsAtom,
  notificationsAtom,
  userAtom,
  userSubscriptionsAtom,
} from '../../../atoms/Atoms';
import {
  fadeOutMotion,
  fadeInMotion,
  getRecurrence
} from '../../../functions';
import { Spinner } from '../../shared-components/Spinner';
import Button from '../../shared-components/buttons/Button';
import {
  backdropVariants,
  sidebarVariants,
  modalVariants
} from '../../../utils';
import envConfig from '../../../envConfig';
import moment from 'moment';
import './shared-components/events.css';
import Loader from '../../shared-components/loader/Loader';
import './shared-components/events.css';
import TimeSlotCardLanding from './shared-components/TimeSlotCardLanding';

function EventsLandingPage() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dayjs = require('dayjs')
  const utc = require('dayjs/plugin/utc')
  dayjs.extend(utc)
  const ref = useRef();
  const paymentsRef = useRef();

  const user = useRecoilValue(userAtom);
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [payment, setPayment] = useRecoilState(userPaymentsAtom);
  const [selectedTimeSlot, setSelectedTimeSlot] = useRecoilState(selectedRentalTimeSlot);
  const [endDate, setEndDate] = useState(null);
  const [selectedTimeSlotDay, setSelectedTimeSlotDay] = useState(null);
  const [timeSlotDays, setTimeSlotDays] = useState([]);
  const [showPaymentsModal, setShowPaymentsModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCheckedTos, setIsCheckedTos] = useState(false);
  const [userSubscriptions, setUserSubscriptions] = useRecoilState(userSubscriptionsAtom);
  const [loadedSubscriptions, setLoadedSubscriptions] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [selectedBenefit, setSelectedBenefit] = useState(null);
  const [price, setPrice] = useState(null);

  const closePaymentsModal = () => {
    setShowPaymentsModal(false);
  };
  const [eventDetails, setEventDetails] = useState(null);
  const [eventSlots, setEventSlots] = useState(null);

  const { eventId } = useParams();

  const openTermsModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  function handleCheckboxChangeTosAccept() {
    // setIsCheckedTos(true);
    setIsModalVisible(false);
  }

  function handleCheckboxChange() {
    setIsChecked(!isChecked);
  }

  function handleCheckboxChangeTos() {
    setIsCheckedTos(!isCheckedTos);
    setIsChecked(!isChecked);
  }

  function handleToggle() {
    setIsExpanded(!isExpanded);
  }

  const agreementsList = [
    { text: t('event_agreement_option_1'), index: '1' },
    { text: t('event_agreement_option_2'), index: '2' },
    { text: t('event_agreement_option_3'), index: '3' }
  ];

  function searchTimeSlots() {
    if (!endDate || !eventId) {
      return;
    }
    setSelectedTimeSlotDay(null);
    setTimeSlotDays([]);
    setLoading(true);
    bookingApi.get(`events/info/${eventId}/timeslotsByDay?endDate=${endDate}`)
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('event_not_found')}`,
              description: response.data.error,
              error: true,
            },
          ]);
          navigate('/events');
        } else {
          setEventDetails(response.data.data);
          setTimeSlotDays(response.data.data.days);
          //setEventSlots(_.filter(response.data.data.timeSlots, (slot) => moment(slot.startTime).isAfter(moment())));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function createFreeReservation(card, payPalInvoice, payPalReference, athMovilReference) {
    setLoading(true);
    bookingApi.post('events/booking', {
      EventTimeSlotId: selectedTimeSlot?.id,
      UserCardId: card?.id,
      PaypalInvoiceNumber: payPalInvoice,
      PaypalReferenceNumber: payPalReference,
      AthMovilReferenceNumber: athMovilReference,
      Amount: selectedTimeSlot?.event?.price,
      BypassPayment: true
    })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          if (response.data.message) {
            setNotifications([
              ...notifications,
              {
                title: '',
                description: response.data.message,
                error: false,
              },
            ]);
          }
          closePaymentsModal();
          searchTimeSlots();
        } else {
          setNotifications([
            ...notifications,
            {
              title: `${t('load_error')}`,
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: `${t('load_error')}`,
            description: err.message,
            error: true,
          },
        ]);
      });
  }

  function createReservation(card, payPalInvoice, payPalReference, athMovilReference) {
    setLoading(true);
    bookingApi.post('events/booking', {
      EventTimeSlotId: selectedTimeSlot?.id,
      UserCardId: card?.id,
      PaypalInvoiceNumber: payPalInvoice,
      PaypalReferenceNumber: payPalReference,
      AthMovilReferenceNumber: athMovilReference,
      Amount: selectedTimeSlot?.event?.price,
      BypassPayment: false
    })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          if (response.data.message) {
            setNotifications([
              ...notifications,
              {
                title: '',
                description: response.data.message,
                error: false,
              },
            ]);
          }
          closePaymentsModal();
          searchTimeSlots();
        } else {
          setNotifications([
            ...notifications,
            {
              title: `${t('load_error')}`,
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: `${t('load_error')}`,
            description: err.message,
            error: true,
          },
        ]);
      });
  }

  function getUserSubscriptions() {
    if(!user) return;
    setLoading(true);
    bookingApi
      .get(`subscriptions/${user.id}/active`)
      .then((response) => {
        setLoading(false);
        setUserSubscriptions(response.data.data);
        hasActiveSubscription(response.data.data);

        setLoadedSubscriptions(true);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  function hasActiveSubscription(subObj) {
    if(subObj?.length === 0) return false;
    else if (subObj?.length > 0) {
      return _.filter(subObj, (s) => s.isActive).length > 0;
    }
    else return false;
  }

  function applyPrice(ben) {
    let tempPrice = selectedTimeSlot?.event?.price;
    if (ben.limit > 0) {
      if (ben?.type === 0) {
        tempPrice -= ben?.amount;
      } else if (ben?.type === 1) {
        tempPrice -= (tempPrice * ben?.amount);
      } else if (ben?.type === 2) {
        tempPrice = ben?.amount;
      } else if (ben?.type === 3) {
        tempPrice = 0;
      }
      setPrice(tempPrice);
    }
  }

  function checkSubscriptions() {
    if (userSubscriptions && selectedTimeSlot) {
      for(let i = 0; i < userSubscriptions?.length; i++) {
        if(userSubscriptions[i]?.subscription?.location === null || (userSubscriptions[i]?.subscription?.location?.id === selectedTimeSlot?.event?.location?.id)) {
          let foundBenefit = false;
          for(let j = 0; j < userSubscriptions[i]?.remainingBenefits.length; j++) {
            const isBenefitMatch = userSubscriptions[i]?.remainingBenefits[j]?.applicableServiceTypeId === selectedTimeSlot?.event?.serviceTypeId;
            if (isBenefitMatch) {
              if (userSubscriptions[i]?.remainingBenefits[j]?.canBeUsed) {
                setSelectedBenefit(userSubscriptions[i]?.remainingBenefits[j]);
                applyPrice(userSubscriptions[i]?.remainingBenefits[j]);
                foundBenefit = true;
              }
            }
          }
          if (foundBenefit) {
            setSelectedSubscription(userSubscriptions[i]);
          } else {
            setPrice(selectedTimeSlot?.event?.price);
          }
        }
      }
    } else {
      setSelectedSubscription(null);
      setSelectedBenefit(null);
    }
  }

  const close = () => {
    if (showPaymentsModal) {
      closePaymentsModal();
    } else {
      setSelectedTimeSlot(null);
    }
  };

  useClickAway(ref, () => {
    close()
  });

  useClickAway(paymentsRef, () => {
    closePaymentsModal()
  });

  useEffect(() => {
    if (showPaymentsModal) {
      // fetchPayments();
    } else {
      setSelectedSubscription(null);
      setSelectedBenefit(null);
    }
  }, [showPaymentsModal]);

  useEffect(() => {
    if (user) {
      getUserSubscriptions();
    }
  }, [user]);

  useEffect(() => {
    if (!selectedTimeSlot) {
      getUserSubscriptions();
    } else {
      setPrice(selectedTimeSlot?.event?.price);
      checkSubscriptions();
    }
  }, [selectedTimeSlot]);

  useEffect(() => {
    if (endDate) {
      searchTimeSlots();
    }
  }, [endDate]);

  useEffect(() => {
    if (eventId) {
      searchTimeSlots();
    }
  }, [eventId]);

  useMount(() => {
    if(!eventId) {
      navigate('/events');
      return
    }
  });

  useEffect(() => {
    setEndDate(dayjs().utc().endOf('day').add(3, 'day').format('YYYY-MM-DDTHH:mm:ss.00'));
  }, []);

  return (
    <AnimatePresence key="eventslanding">
      <div className="events-landing" />
      <div className="mt-4">
        {loading && (
          <div className="absolute mx-0 mt-24 mb-2 w-full text-center justify-center items-center">
            <div className="flex mt-24" >
              <div className="mx-auto mt-24"><Loader color="bg-yellow" /></div >
            </div>
          </div>
        )}
        <div className="mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-20 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            {/* Page Header */}
            
            <div className="mt-2 md:mt-4 grid-col-1">
              <h1 className="text-2xl font-bold tracking-tight text-white md:text-4xl">{eventDetails?.name}</h1>
              <div className="flex items-center">
                <p className={`text-lg sm:text-xl ${eventDetails?.price === 0 ? 'text-green-500 font-semibold' : 'text-white'}`}>{eventDetails?.price > 0 ? '$' : null}{eventDetails?.price === 0 ? t('free_entrance') : eventDetails?.price.toFixed(2)}</p>
              </div>
              <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg md:block hidden bg-red-400 mt-4">
                <img hidden={!eventDetails?.imageUrl} src={eventDetails?.imageUrl} alt="Event" className="h-full w-full object-cover object-center" />
              </div>
            </div>
              {/* Product image */}
            <div className="md:hidden block mt-8 lg:col-start-1 lg:row-span-2 lg:mt-0 lg:self-center">
              <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg">
                <img hidden={!eventDetails?.imageUrl} src={eventDetails?.imageUrl} alt="Event" className="h-full w-full object-cover object-center" />
              </div>
            </div>
            {eventDetails && ( 
            <section aria-labelledby="information-heading" className="mt-4">
              <h2 id="information-heading" className="sr-only">
                Event Details
              </h2>
              <div className="hidden items-center">
                <p className={`text-lg sm:text-xl ${eventDetails?.price === 0 ? 'text-green-500 font-semibold' : 'text-white'}`}>{eventDetails?.price > 0 ? '$' : null}{eventDetails?.price === 0 ? t('free_entrance') : eventDetails?.price.toFixed(2)}</p>
              </div>
              <div className="mt-2 space-y-4 text-md sm:text-sm font-semibold">
                <p className=" text-white">{eventDetails?.description}</p>
              </div>
              <div className="mt-2 space-y-4">
                <p className="text-white"><FontAwesomeIcon icon={faWarehouse} className="text-yellow-400 h-4 w-4 mr-2" />{eventDetails?.location?.name}</p>
              </div>            
              <div>             
                <div>
                  <button
                    key={`button-${eventDetails?.id}`}
                    type="button"
                    href={`tel:${eventDetails?.name}`}
                    className="items-center justify-center gap-x-3 rounded-br-lg py-2 font-normal text-white"
                  >
                    <FontAwesomeIcon icon={faCalendar} className="h-4 w-4 text-yellow-400 mr-2" aria-hidden="true" />
                    {t('dates_and_availability')}
                  </button>
                </div>
                {!loading && eventDetails && timeSlotDays?.length === 0 && (
                  <div className="no-available-slots">{t('no_classes_available')}</div>
                )}
                {_.map(timeSlotDays, (day) => (
                  <div className="mt-2" key={`${eventDetails?.id}-${day.startDate}`}>
                    <motion.button
                      initial={fadeOutMotion}
                      animate={fadeInMotion}
                      exit={fadeOutMotion}
                      key={`${eventDetails?.id}-${day.startDate}-button`}
                      type="button"
                      onClick={() => setSelectedTimeSlotDay(day)}
                      className={`${day.startDate === selectedTimeSlotDay?.startDate ? 'ring-2 ring-white': ''} w-full disabled:cursor-not-allowed text-center font-medium line-clamp-1 outline-none focus:outline-none transition duration-500 ease-in-out`}
                    >
                      <div className={`${day.startDate === selectedTimeSlotDay?.startDate ? 'border-black border-2': ''} bg-yellow h-20 text-md items-center justify-center text-black font-bold tracking-tight leading-none py-6 px-2`}>
                        <div className="flex items-center justify-center my-auto">
                          <span className="line-clamp-1">{`${dayjs(day?.startDate).add(4, 'hour').format('dddd, MMMM DD, YYYY')}`}</span>
                          <span className={`${dayjs().format('DD') !== dayjs(day?.startDate).format('DD') && 'hidden'} line-clamp-1 ml-2`}>({t('today')})</span>
                        </div>
                      </div>
                    </motion.button>    
                  </div>
                ))}
                {selectedTimeSlotDay && (
                  <>
                    {_.map(_.sortBy(selectedTimeSlotDay?.timeSlots, 'startTime'), (x, i) => (<TimeSlotCardLanding key={x.id} timeSlot={x} index={i} />))}
                  </>
                )}
              </div>             
            </section>)}        
        </div>
      </div>
      {selectedTimeSlot && !showPaymentsModal && !payment.modal && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          key="sidebar"
          exit="hidden"
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-20"
        >
          <motion.div
            ref={ref}
            variants={sidebarVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className="absolute right-0 h-full flex flex-col w-full md:w-1/4 bg-black"
          >
            <div className="px-4 py-2 mt-24 flex items-center justify-between text-white">
              {t('details')}
              <button
                type="button"
                onClick={() => {
                  setSelectedTimeSlot(null);
                  setIsChecked(false);
                }}
                className="hover:opacity-75 outline-none focus:outline-none"
              >
                <FontAwesomeIcon icon={faTimesCircle} className="text-xl text-white" />
              </button>
            </div>
            <div className="flex flex-col flex-1 overflow-auto p-4">
              {selectedTimeSlot?.event?.imageUrl && (
                // Shows image if one is set to the event or location
                <img alt={selectedTimeSlot?.event?.name} src={selectedTimeSlot?.event?.imageUrl} className="h-52 w-auto object-cover hidden rounded-lg" />
              )}
              <div className="flex">
                  <div className="my-2 text-md font-bold">{selectedTimeSlot?.event?.name}</div>
                  {/* <div className={`my-2 ml-auto text-md font-bold ${selectedTimeSlot?.event?.price === 0 ? 'text-green-500' : 'text-white'}`}>
                    {selectedTimeSlot?.event?.price > 0 ? '$' : null}
                    {selectedTimeSlot?.event?.price === 0 ? t('free_entrance') : selectedTimeSlot?.event?.price.toFixed(2)}
                  </div> */}
                  <div className={`my-2 ml-auto text-md font-bold ${price === 0 ? 'text-green-500' : 'text-white'}`}>
                    {price > 0 ? '$' : null}
                    {price === 0 ? t('free_entrance') : price?.toFixed(2)}
                  </div>
              </div>
              <div className={selectedSubscription && selectedSubscription.isActive? 'block bg-blue-500 rounded-md p-4 my-4' : 'hidden'}>
                <div className="text-center font-semibold">{t('subscription')}</div>
                <div className={selectedBenefit ? 'block text-center p-2 text-sm' : 'hidden'}>{`You have ${selectedBenefit?.totalLimit} drop-in(s) allowed ${getRecurrence(selectedBenefit?.recurrence)}`}</div>
                <div className={selectedBenefit && selectedBenefit?.totalLimit > selectedBenefit?.limit ? 'block text-center p-2 text-sm' : 'hidden'}>{`You have ${selectedBenefit?.limit} remaining`}</div>
                <div className="text-center text-sm">{`Expires ${moment().to(selectedSubscription?.endDate)}`}</div>
              </div>


              <div className="my-2 text-xs md:text-sm tracking-tight text-justify">{selectedTimeSlot?.event?.description}</div>   
              <div className={`${selectedTimeSlot?.timeSlotHost === null ? 'hidden' : 'text-sm mt-1'}`}>{t('instructor')}: {`${selectedTimeSlot?.timeSlotHost?.completeName}`}</div>
              <div className="text-xs md:text-sm mt-1">{t('date')}: {`${dayjs(selectedTimeSlot?.startTime).add(-4, 'hour').format('dddd MMMM D, YYYY')}`}</div>
              <div className="text-xs md:text-sm mt-1">{t('slot')}: {`${dayjs(selectedTimeSlot?.startTime).add(-4, 'hour').format('h:mm a')}-${dayjs(selectedTimeSlot?.endTime).add(-4, 'hour').format('h:mm a')}`}</div>        
              <div className="text-xs md:text-sm mt-1">{t('location')}: {`${eventDetails?.location.name}`}</div>
              <div className={selectedTimeSlot?.comment ? '' : 'hidden'}>{t('comments')}: {selectedTimeSlot?.comment}</div>
              {!user && (
                <Button
                  text={t('login_to_dropin')}
                  textColor="text-white"
                  onClick={() => {
                  navigate('/login', { from: `events/${eventId}` });
                }}
                  loaderColor="bg-white"
                  className="bg-yellow-500 text-lg hover:bg-yellow-600 mt-4"
                />
              )}
              {/* Let the user book*/}
              {!selectedTimeSlot?.isFull && !selectedTimeSlot?.reservedByUser && user && (
              <div>
                <div className="flex flex-col items-start mt-8 bg-green-600 rounded-xl p-4 border-t border-green-600">
                  <label className="text-sm font-semibold">{t('event_terms')}</label>
                  <button 
                    hidden
                    type="button"
                    onClick={handleToggle}
                  >
                    {isExpanded ? 'show less' : 'show less'} List
                  </button>
                {isExpanded && (
                  <ul className="text-sm">
                    {_.map(agreementsList, (item, i) => (
                      <li key={`${item.index}-${i}`}>-{item.text}</li>
                    ))}
                  </ul>
                )}
                <div className="text-sm mt-4">
                  <span>{t('if_cancel_booking_contact')}
                  </span>
                  <a href={`mailto:${envConfig.ContactEmail}`} className=" ml-2 font-medium text-yellow-400 dark:text-yellow-400 hover:underline">{`${envConfig.ContactEmail}`}</a>
                </div>
                </div>      
                <div className="flex items-center mx-1 mt-2 mb-4">
                  <input
                    key="tos_agreement"
                    type="checkbox"
                    className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    checked={isCheckedTos}
                    onChange={handleCheckboxChangeTos}
                  />
                  <label htmlFor="agreement" className="ml-4 text-xs">
                    {t('tos_agreement')}{' '}
                    <span
                      className="text-blue-500 cursor-pointer underline"
                      onClick={openTermsModal}
                    >
                      {t('terms_and_conditions')}
                    </span>
                  </label>
                </div>
              </div>)}
              {/* User already has a booking for this slot */}
              {selectedTimeSlot?.reservedByUser && user && (
                <div className="my-4 text-center text-white bg-purple-600 rounded-xl p-2 text-lg">
                {t('already_reserved')}
                </div>
              )}
              {/* User can book by paying */}
              {!selectedTimeSlot?.isFull 
              && !selectedTimeSlot?.reservedByUser && user 
              && price > 0 && (
                <PayPalScriptProvider options={{ 'client-id': envConfig.PaypalClientId,'disable-funding': 'card', 'enable-funding': 'venmo'}}>
                <PayPalButtons
                  disabled={!isChecked}
                  createOrder={(data, actions) => actions.order.create({
                    purchase_units: [
                        {
                            description: `${selectedTimeSlot?.event?.name} ${dayjs(selectedTimeSlot?.startTime).add(-4, 'hour').format('MMM D YYYY')} ${dayjs(selectedTimeSlot?.startTime).add(-4, 'hour').format('h:mm a')}-${dayjs(selectedTimeSlot?.endTime).add(-4, 'hour').format('h:mm a')}`,
                            amount: {
                                value: `${(price).toFixed(2)}`,
                            },
                            custom_id: `${user?.id} ${selectedTimeSlot?.id}`
                        },
                    ],
                    application_context: { brand_name: 'Fit Boxing', locale: `${i18n.language}-US`, shipping_preference: 'NO_SHIPPING' }
                  })}
                  onApprove={(data, actions) => actions.order.capture().then((details) => {
                    const refNumber = details.id;
                    const invoiceNumber =
                      details.purchase_units[0]?.payments?.captures[0]?.id;
                    createReservation(null, invoiceNumber, refNumber, null);
                  })}
                />
              </PayPalScriptProvider>
              )}
               {/*This is a free booking because it cost $0 to book or the price has been set to zero because the user usbcription has a benefit*/}
               {!selectedTimeSlot?.isFull 
              && !selectedTimeSlot?.reservedByUser && user 
              && (selectedTimeSlot?.event?.price === 0 || price === 0)  && (
                <Button
                  text={t('reserve_free_spot')}
                  textColor="text-black"
                  disabled={loading || !isChecked}
                  loading={loading}
                  onClick={() => {
                    createFreeReservation(null, null, null, null);
                    setIsChecked(false);
                    //Close the modal
                    close();
                  }}
                  loaderColor="bg-white"
                  className="bg-green-400 mt-4 hover:ring-2 h-14 md:h-20 p-8 mb-10 ring-offset-1 ring-yellow-500 text-2xl"
                />
              )}
               {/*Class is full. All slots are taken already */}
              {selectedTimeSlot && (selectedTimeSlot?.isFull ||
               (selectedTimeSlot?.maxCapacity === selectedTimeSlot?.currentCapacity)) &&
                !selectedTimeSlot?.reservedByUser && user && (
                <Button
                  text={t('class_is_full')}
                  textColor="text-black"
                  disabled
                  loaderColor="bg-white"
                  className="bg-red-400 hover:ring-2 ring-offset-2 ring-red-500 text-lg mt-4"
                />
              )}  
              {selectedTimeSlot?.reservedByUser && user && (
                <div className="my-4 text-center text-gray-100 p-2 text-lg border rounded-xl border-double border-spacing-1">
                {t('already_have_reservation')}
                </div>
              )}            
            </div>
          </motion.div>
        </motion.div>
      )}
       <Modal
        title={t('terms_conditions')}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}
        footer={<button className="acceptTermsButton" onClick={handleCheckboxChangeTosAccept}>{t('I Accept')}</button>} 
        width={500}
        style={{ maxHeight: '85vh', overflow: 'auto' }}
      >
        <div>
          <p>{t('tos_content_1')}</p>
          <p>{t('tos_content_2')}</p>
          <p>{t('tos_content_3')}</p>
          <p>{t('tos_content_4')}</p>
          <p>{t('tos_content_5')}</p>
        </div>
      </Modal>
      {selectedTimeSlot && showPaymentsModal && !payment.modal && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          key="payments"
          exit="hidden"
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
        >
          <motion.div
            variants={modalVariants}
            className="text-gray-800 antialiased justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div
              ref={paymentsRef}
              className="flex flex-col bg-white rounded-xl mx-auto"
            >
              <header className="flex items-center justify-between bg-gray-800 text-white rounded-t-xl px-4 py-6">
                <div className="flex items-center space-x-1 font-bold tracking-tight text-xl leading-tight">
                  {t('amount_to_pay')}: ${(selectedTimeSlot?.event?.price).toFixed(2)}
                </div>
                <button
                  type="button"
                  onClick={() => closePaymentsModal()}
                  className="text-xl leading-none ring-0 outline-none focus:outline-none"
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </button>
              </header>
              {/* {loading && <Spinner className="mx-auto mt-2" />} */}
              <main className="group relative flex flex-1 overflow-y-auto bg-gray-50 rounded-b-xl p-4">
                <div className="flow">
                  {/* <div>{t('payment_method_description')}</div>
                  <Spinner
                    spinning={loading}
                    className="mx-auto my-4"
                  /> */}
                  {/* {envConfig.AllowCreditCardCreate === 'true' && envConfig.AllowCreditCardsInBooking === 'true' && (
                    <button
                      type="button"
                      onClick={() => {
                        setPayment({ ...payment, modal: true });
                      }}
                      className="text-left w-full mt-4 transform hover:scale-90 shadow rounded-xl flex h-20 transition duration-500 ease-linear ring-2 ring-offset-2 ring-amber-400 border border-amber-400 outline-none focus:outline-none"
                    >
                      <div className="w-16 h-full flex flex-shrink-0 items-center justify-center text-white text-3xl bg-amber-400 rounded-l-xl">
                        <FontAwesomeIcon icon={faCreditCard} />
                      </div>
                      <div className="flex flex-col justify-center p-4 text-xs text-gray-800">
                        <div className="font-semibold">{t('add_new_payment')}</div>
                        <div>{t('new_card')}</div>
                      </div>
                    </button>
                  )} */}
                  {selectedTimeSlot && selectedTimeSlot?.event?.price > 0 (
                    <div className="mt-6">
                      <PayPalScriptProvider options={{ 'client-id': envConfig.PaypalClientId,'disable-funding': 'card', 'enable-funding': 'venmo'}}>
                        <PayPalButtons
                          disabled={!isChecked}
                          createOrder={(data, actions) => actions.order.create({
                            purchase_units: [
                                {
                                    description: `${selectedTimeSlot?.event?.name} ${dayjs(selectedTimeSlot?.startTime).add(-4, 'hour').format('MMM D YYYY')} ${dayjs(selectedTimeSlot?.startTime).add(-4, 'hour').format('h:mm a')}-${dayjs(selectedTimeSlot?.endTime).add(-4, 'hour').format('h:mm a')}`,
                                    amount: {
                                        value: `${(selectedTimeSlot?.event?.price).toFixed(2)}`,
                                    },
                                    custom_id: `${user?.id} ${selectedTimeSlot?.id}`
                                },
                            ],
                            application_context: { brand_name: 'Fit Boxing', locale: `${i18n.language}-US`, shipping_preference: 'NO_SHIPPING' }
                          })}
                          onApprove={(data, actions) => actions.order.capture().then((details) => {
                            const refNumber = details.id;
                            const invoiceNumber =
                              details.purchase_units[0]?.payments?.captures[0]?.id;
                            createReservation(null, invoiceNumber, refNumber, null);
                          })}
                        />
                      </PayPalScriptProvider>
                    </div>
                  )}
                  {envConfig.AthMovilForBookings === 'true' && !loading && (
                    <></>
                  )}
                  {envConfig.AllowCreditCardsInBooking === 'true' && _.map(payment.cards, (card, cind) => (
                    <button
                      type="button"
                      onClick={() => createReservation(card, null, null, null)}
                      key={cind}
                      className="w-full"
                    >
                      <div
                        className="divide-x mt-4 divide-purple-200 flex shadow rounded-xl h-20 transition duration-500 ease-linear ring-2 ring-offset-2 ring-purple-400 border border-purple-400 outline-none focus:outline-none"
                      >
                        <div className="w-16 flex flex-col items-center justify-center text-white text-3xl bg-purple-600 rounded-l-xl">
                          <FontAwesomeIcon icon={faCreditCard} />
                        </div>
                        <div className="flex flex-col flex-1 items-start justify-start text-xs p-2">
                          <div className="font-semibold">{card.brand}</div>
                          <div>**** {card.last4}</div>
                          <div>{card.expirationDate}</div>
                        </div>
                      </div>
                    </button>
                  ))}
                </div>
              </main>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default EventsLandingPage;
