import React from "react";
import "../shared-components/EventCard.css";

function EventCard({ imageSrc, eventName, eventDate, eventId }) {
  const handleButtonClick = () => {
    window.location.href = `/event/${eventId}`;
  };

  return (  
    <div className="service-card" key={eventId}>
      <div className="card-image-container">
        <img src={imageSrc} alt="EventImage" />
        <div className="tag hidden ">Coming Soon</div>
      </div>
      <div className="card-content">
        <h1>{eventName}</h1>
        <p className="date">{eventDate}</p>
        <button 
          className="services-register-btn"
          onClick={handleButtonClick}>       
          <a href="#" className="">
            Explore Availability
          </a>
        </button>
      </div>
    </div>
  );
}

export default EventCard;
