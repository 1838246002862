import './App.css';
import '../assets/css/main.css';
import '../assets/css/custom.css';
import { useLocalStorage, useMount } from 'react-use';
import envConfig from '../envConfig';
import {
  useRecoilValue, useRecoilState, useSetRecoilState,
} from 'recoil';
import routes from '../routes/index';
import _ from 'lodash';
import { AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-use';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { themeAtom, tokenAtom, userAtom } from '../atoms/Atoms';
import ProtectedRoute from '../routes/ProtectedRoutes';
import Header from './shared-components/header/Header';
import Footer from './shared-components/footer/Footer';
import { useTranslation } from 'react-i18next';
import Navbar from './shared-components/navbar/Navbar';
import Logo from './shared-components/logo/Logo';
import Notifications from './shared-components/notifications/Notifications';
import NewHeader from './shared-components/header/NewHeader';
import WarningBanner from './shared-components/warning-banner/WarningBanner';

function App() {
  const { i18n } = useTranslation();
  const theme = useRecoilValue(themeAtom);
  const location = useLocation();
  const setToken = useSetRecoilState(tokenAtom);
  const [language] = useLocalStorage(`${envConfig.Prefix}-lang`);
  const [token] = useLocalStorage(`${envConfig.StorageKey}-token`);
  const [user] = useLocalStorage(`${envConfig.StorageKey}-user`);
  const [userA, setUser] = useRecoilState(userAtom );
 
  useMount(() => {
    if(language) { localStorage.setItem(`${envConfig.Prefix}-lang`,JSON.stringify(language)) }
    else {
    i18n.changeLanguage('en');
    localStorage.setItem(`${envConfig.Prefix}-lang`,JSON.stringify('en'))
    }

    if (user) {
      setUser(user);
    }

    if (token) {
      setToken(token);
    }

  });


  return (
    <div 
      className={`${theme} wrapper overflow-auto fontDefaults antialiased`}
  >
      <AnimatePresence key="app" mode="exit" initial={false}>
        <Router>
          <Navbar/>
          <Header/>
          <Logo/>
          <Routes location={location} key={location.pathname}>
            {_.map(routes, (route) => {
              if (route.protected) {
                return (
                  <ProtectedRoute
                    key={route.id}
                    path={route.path}
                    exact={route.exact}
                    element={route.element}
                  />
                );
              }
              return (
                <Route
                key={route.id}
                path={route.path}
                exact={route.exact}
                element={<route.element />}
                />
              );
              })}
          </Routes>
          <Footer/>
          <Notifications/> 
        </Router>
      </AnimatePresence>
    </div>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
