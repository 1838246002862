import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useSetRecoilState } from 'recoil';
import {
  toLocalTime
} from '../../../../functions';
import {
  selectedRentalTimeSlot
} from '../../../../atoms/Atoms';
import '../shared-components/events.css';

function TimeSlotCardLanding({ timeSlot, index }) {
  const { t } = useTranslation();
  const setSelectedTimeSlot = useSetRecoilState(selectedRentalTimeSlot);

  return (
    <motion.button
      type="button"
      key={timeSlot?.id}
      disabled={timeSlot?.isFull}
      onClick={() => setSelectedTimeSlot(timeSlot)}
      className="timeslot-style md:mt-4 mt-6 w-full disabled:cursor-not-allowed text-left font-medium outline-none focus:outline-non transition duration-500 ease-in-out"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.2 }}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <div className="bg-yellow text-md px-4 py-2 flex flex-row gap-4 items-center">
        {/* Date Info in a Separate Container, Spanning One Column */}
        <div>
          <div className="flex text-sm ml-2 text-lg font-bold text-center uppercase">
            {`${toLocalTime(timeSlot?.startTime).format('MMM')}`}
          </div>
          <div className="text-sm ml-2 text-xl font-bold text-center">
            {`${toLocalTime(timeSlot?.startTime).format('D')}`}
          </div>
        </div>
        {/* Column for Time Slot */}
        <div>
          <div className="text-sm ml-2 text-lg font-bold">
            {`${toLocalTime(timeSlot?.startTime).format('h:mm A')} - ${toLocalTime(timeSlot?.endTime).format('h:mm A')}`}
          </div>

          {/* Column for Availability or Full Class Indicator */}
          <div>
            {timeSlot?.isFull ? (
              <span className="full-class-text">{t('full_class')}</span>
            ) : (
              <span className="availability-text">{`(${timeSlot?.maxCapacity - timeSlot?.currentCapacity} spot(s) available)`}</span>
            )}
          </div>
        </div>
        {/* Column for Reserve a Spot (if not full) */}
        <div>
          {!timeSlot?.isFull && (
            <span className="action-class-text">{`${timeSlot?.reservedByUser ? t('already_reserved') : t('reserve_a_spot')}`}</span>
          )}
        </div>
      </div>
    </motion.button>
  );
}

export default TimeSlotCardLanding;
