import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import './css/overlay.css'

function OverlayMessage() {

   return (
          <div className="overlayBody">
            <div className="overlaytext">
             {/* <div>"Unleash Your Strength,</div>
             <div>Elevate Your Fitness."</div> */}
             <div>Your fitness journey starts here!</div>
            </div>
          </div>
  );
}

export default OverlayMessage;
