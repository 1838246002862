import React from 'react';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../../atoms/Atoms';
import Avatar from './shared-components/Avatar';
import envConfig from '../../../envConfig';
import './shared-components/user.css';
import '../../../assets/css/custom.css';
import { formatPhoneNumber } from '../../../functions';

function User() {
  const user = useRecoilValue(userAtom);
  if (user) {
    return <div className="fontDefaults userStyle flex flex-row-reverse md:flex-row text-xs md:text-sm justify-end">
        <div className="mx-2">
          <div className="font-medium tracking-tight truncate hidden md:block">
            {user.completeName}
          </div>
          <div className="font-medium text-md tracking-tight truncate block md:hidden">
            {user.firstName}
          </div>
          <div className="font-light tracking-tight truncate hidden md:block">{user.email}</div>
          <div className="font-light tracking-tight truncate md:hidden block">{formatPhoneNumber(user.phoneNumber)}</div>
          <div className="font-light tracking-tight truncate text-yellow-300 text-xs block md:hidden">v 2024.1.0</div>
        </div>
        <Avatar />
      </div>
  }
  return null;
}

export default User;
