// Drawer.js
import React, { useEffect, useState } from 'react';
import './shared-components/Drawer.css';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useMount } from 'react-use';
import { XMarkIcon, ClockIcon, ArrowPathIcon, MapPinIcon, UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import {
  notificationsAtom,
  refreshTimeSlotsAtom,
  userAtom,
  selectedDropInLocationAtom,
  selectedDropInDateAtom,
  isPaymentDrawerOpenAtom,
  selectedTimeSlotAtom,
  userSubscriptionsAtom
} from '../../../atoms/Atoms';
import bookingApi from '../../../api/bookingApi';
import Button from '../buttons/Button';
import envConfig from '../../../envConfig';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

const PaymentDrawer = () => {
  const { t, i18n } = useTranslation();
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [showPaymentsModal, setShowPaymentsModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  // const [hasAvailableDropins, setHasAvailableDropins] = useState(false);
  // const [subLocationMatch, setSubLocationMatch] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [loading, setLoading] = useState(false);
  const [resultMessage, setResultMessage] = useState(null);
  const user = useRecoilValue(userAtom);
  const [submitting, setSubmitting] = useState(false);
  const [refreshTimeSlots, setRefreshTimeSlots] = useRecoilState(refreshTimeSlotsAtom);

  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [selectedBenefit, setSelectedBenefit] = useState(null);
  const [price, setPrice] = useState(null);

  const [selectedLocation, setSelectedLocation] = useState(selectedDropInLocationAtom);
  const [isDrawerOpen, setIsDrawerOpen] = useRecoilState(isPaymentDrawerOpenAtom);
  const [selectedDate, setSelectedDate] = useRecoilState(selectedDropInDateAtom);
  const [selectedTimeSlot, setSelectedTimeSlot] = useRecoilState(selectedTimeSlotAtom);
  const [userSubscriptions, setUserSubscriptions] = useRecoilState(userSubscriptionsAtom);

  function CreateBooking(ppRefNumber, ppInvoiceNumber) {
    if (!loading) {
      setLoading(true);
      bookingApi.post('events/booking', {
        EventTimeSlotId: selectedTimeSlot?.id,
        UserCardId: null,
        PaypalInvoiceNumber: ppInvoiceNumber,
        PaypalReferenceNumber: ppRefNumber,
        AthMovilReferenceNumber: null,
        Amount: price,
        BenefitId: selectedBenefit?.id
      })
        .then((response) => {
        setLoading(false);
        setIsChecked(false);
        if (response.data.success) {
          // If there is a message from the API it is displayed
          if (response.data.message) {
            setNotifications([
              ...notifications,
              {
                title: '',
                description: response.data.message,
                error: false,
              },
            ]);
          }
          setIsDrawerOpen(false);
          setRefreshTimeSlots(!refreshTimeSlots);

          // TODO: Handle success from API here

        } else {
          // If there was an error handling the request then show error message from API
          setNotifications([
            ...notifications,
            {
              title: `${t('load_error')}`,
              description: response.data.error,
              error: true,
            },
          ]);

          setIsDrawerOpen(false);
          setRefreshTimeSlots(!refreshTimeSlots);
          setResultMessage('You are already registered for this time slot. You can find a receipt in your reservations.')
        }
        })
        .catch((err) => {
        // If there is an unhandled exception it is handled here
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: `${t('load_error')}`,
            description: err.message,
            error: true,
          },
        ]);
        });
    }
  }

  function applyPrice(ben, price) {
    let tempPrice = price;
    if (ben && ben?.limit > 0) {
      if (ben?.type === 0) {
        tempPrice -= ben?.amount;
      } else if (ben?.type === 1) {
        tempPrice -= (tempPrice * ben?.amount);
      } else if (ben?.type === 2) {
        tempPrice = ben?.amount;
      } else if (ben?.type === 3) {
        tempPrice = 0;
      }
    }
    setPrice(tempPrice);
  }

  function checkSubscriptions() {
    if (userSubscriptions && selectedTimeSlot) {
      let tempSub = null;
      let tempBen = null;
      let tempPrice = selectedTimeSlot?.event?.price;
      for(let i = 0; i < userSubscriptions?.length; i++) {
        if(userSubscriptions[i]?.subscription?.locationId === selectedTimeSlot?.event?.location?.id) {
          let foundBenefit = false;
          for(let j = 0; j < userSubscriptions[i]?.remainingBenefits.length; j++) {
            const isBenefitMatch = userSubscriptions[i]?.remainingBenefits[j]?.applicableServiceTypeId === selectedTimeSlot?.event?.serviceTypeId;
            if (isBenefitMatch) {
              if (userSubscriptions[i]?.remainingBenefits[j]?.canBeUsed) {
                tempBen = userSubscriptions[i]?.remainingBenefits[j];
                tempSub = userSubscriptions[i];
                foundBenefit = true;
                break;
              }
            }
          }
          if (foundBenefit) {
            break;
          }
        }
      }
      setSelectedSubscription(tempSub);
      setSelectedBenefit(tempBen);
      applyPrice(tempBen, tempPrice);
    } else {
      setSelectedSubscription(null);
      setSelectedBenefit(null);
      if (selectedTimeSlot) {
        applyPrice(null, selectedTimeSlot?.event?.price);
      }
    }
  }

  function handleCheckboxChange() {
    setIsChecked(!isChecked);
  }

  function handleToggle() {
    setIsExpanded(!isExpanded);
  }

  function getSpacesLeft (timeSlot) {
    return timeSlot.maxCapacity - timeSlot.currentCapacity; 
  }

  useMount(() => {
    setIsChecked(false);
    setResultMessage(null);
  });

  useEffect(() => {
    setPrice(selectedTimeSlot?.event?.price);
    checkSubscriptions();
  }, [userSubscriptions, selectedTimeSlot]);

  useEffect(() => {
    if (!isDrawerOpen) {
      setSelectedSubscription(null);
      setSelectedBenefit(null);
    } else {
      checkSubscriptions();
    }
  }, [isDrawerOpen]);

  //console.log(userSubscriptions)
  console.log('ts',selectedTimeSlot);


  return (
    <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
      <button 
        className="close-button" 
        onClick={()=> {
          setIsDrawerOpen(false);
          setIsChecked(false);
          setResultMessage('');
          }}>
        <div className="flex flex-row">
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          <div className="ml-2">Close</div>
        </div>
      </button>    
      {!user && 
      <div className="mt-24 px-6">Please <a className="text-yellow-500" href="/login">login</a> to drop in</div>}
      {user && 
        <div>
          <div className="drawer-content">
            {/* Your drawer content goes here */}
            <div className={selectedSubscription && selectedSubscription.isActive? 'block bg-blue-500 rounded-md p-4 my-4' : 'hidden'}>
              <div className="text-center font-semibold">Subscription</div>
              {/* <div className={!subLocationMatch ? 'text-center font-bold underline' : 'text-center'}>
                {`in ${!subLocationMatch && userSubscriptions &&
                  userSubscriptions.length > 0 ? userSubscriptions[0]?.subscription?.location?.nameEn
                  : 'this location'}`}
              </div> */}
              <div className={selectedBenefit ? 'block text-center p-2 text-sm' : 'hidden'}>{`You have ${selectedBenefit?.totalLimit} drop-in(s) allowed per week`}</div>
              <div className={selectedBenefit && selectedBenefit?.totalLimit >= selectedBenefit?.limit ? 'block text-center p-2 text-sm' : 'hidden'}>{`You have ${selectedBenefit?.limit} dropin(s) remaining`}</div>
              {/* <div className={selectedBenefit && selectedBenefit?.limit === 0 ? 'block text-center p-2 text-sm' : 'hidden'}>{`You have used up your ${selectedBenefit?.totalLimit} dropin(s) included in your subscription`}</div> */}
              <div className="text-center text-sm">{`Expires ${moment().to(selectedSubscription?.endDate)}`}</div>
            </div>
            <div className="text-yellow-300">Booking for</div>
            <div className="text-yellow-400 truncate">{selectedTimeSlot?.event?.name}</div>
            <div className="text-yellow-400 truncate">{selectedTimeSlot?.event?.description}</div>
            <div className="mt-2">{`${selectedDate && moment(selectedDate.startDate).format('dddd, MMMM DD, YYYY')}`}</div>
            <div className="flex flex-row mt-2">
            <ClockIcon className="h-5 w-5 mr-4 mt-0" aria-hidden="true" />
              <div>{`${selectedTimeSlot && moment(selectedTimeSlot.startTime).add(-4, 'hour').format('h:mm A')}`}</div>
            </div>
            <div className="flex flex-row">
            <MapPinIcon className="h-5 w-5 mr-4 mt-2" aria-hidden="true" />
              <div className="text-white mt-2">{`${selectedTimeSlot?.event?.location?.name}`}</div>
            </div>
            <div className={`${selectedTimeSlot?.timeSlotHost == null ? 'hidden' : 'flex flex-row'}`}>
            <UserIcon className="h-5 w-5 mr-4 mt-2" aria-hidden="true" />
              <div className="text-white mt-2">{`${selectedTimeSlot?.timeSlotHost?.completeName}`}</div>
            </div>
          
            <div className="text-yellow-300 mt-4">{`$${price && price.toFixed(2)}`} (one space)</div>
            <ul className="agreementList">
              {/* <li>All sales are final</li>
              <li>No refunds</li>
              <li>No credits</li> */}
              <li>Total Drop-in spaces: {`${selectedTimeSlot && selectedTimeSlot.maxCapacity}`}</li>
              <li>Spaces Left: <a className="text-yellow-400">{selectedTimeSlot && getSpacesLeft(selectedTimeSlot)}</a></li>
              
            </ul>
            <div className="flex items-center mx-1 mt-8 mb-4">
                    <input
                      type="checkbox"
                      className="agreementCheckbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="agreement" className="acceptance">
                      I have read and accept the terms.
                    </label>
                  </div>
          </div>
          {user && selectedTimeSlot && price === 0 &&
          // Free Class (Zero Cost)
          <div>
            <Button
              text="CheckIn"
              textColor="text-gray-600"
              disabled={!isChecked || loading}
              loading={loading}
              onClick={() => CreateBooking(null, null)}
              loaderColor="bg-white"
              className="mt-12 bg-yellow-400 hover:bg-yellow-500 w-1/2 mr-10 ml-4 text-white"
            />
          </div>}
        {user && selectedTimeSlot && price > 0 &&
          <div className="mt-10 mx-4">
        {isDrawerOpen && (
          <PayPalScriptProvider options={{ 'client-id': envConfig.PaypalClientId,'disable-funding': 'card', 'enable-funding': 'venmo'}}>
            <PayPalButtons
              disabled={!isChecked}
              createOrder={(data, actions) => actions.order.create({
                purchase_units: [
                    {
                        description: `Drop-In for ${selectedLocation.nameEn} ${selectedTimeSlot?.event?.nameEn}  Date: ${moment().add(-4, 'hour').format('MMM D YYYY')}`,
                        amount: {
                            value: `${price.toFixed(2)}`,
                        },
                        custom_id: `${user?.id} ${selectedTimeSlot?.id}`
                    },
                ],
                application_context: { brand_name: 'Fit Boxing', locale: `${i18n.language}-US`, shipping_preference: 'NO_SHIPPING' }
              })}
              onApprove={(data, actions) => actions.order.capture().then((details) => {
                const refNumber = details.id;
                const invoiceNumber =
                  details.purchase_units[0]?.payments?.captures[0]?.id;
                CreateBooking(refNumber, invoiceNumber);
              })}
            />
          </PayPalScriptProvider>
          )}
        </div>}
      <div className="flex flex-col content-center p-6">{resultMessage}</div>
      </div>}
    </div>
  );
};

export default PaymentDrawer;