import React from 'react';
import './home-sections.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import yoga2 from '../../../assets/images/photos/yoga2.jpg';
import throwtheball from '../../../assets/images/photos/throwtheball.jpg';
import fitness from '../../../assets/images/photos/rightkick.jpg';
import crosstraining from '../../../assets/images/photos/leftpunch.jpg';
import fullbody from '../../../assets/images/photos/kneepullup1.jpg';


const HomeSections = () => {
    const navigate = useNavigate();
    
    const navigateToTraining = () => 
        {
            //navigate('/dropin');
            window.location.href = `/event/kE`;
        }  

    const navigateToServices = () => 
        {
            navigate('/services');
        }

    return (
       <div className="main-section">
        <div onClick={navigateToServices}>
            <div className="image-text-section">
                <div className="image-container">
                    <div className="image-overlay">
                        <img 
                            src={crosstraining}
                            alt="Intro" 
                            className="image"
                        />
                    </div>
                </div>
                <div className="text-container">
                    <h2>Crosstraining</h2>
                    {/* <p>
                    "Find balance, flexibility, and inner peace through yoga. Rejuvenate your body and mind in every session. Join us to embark on your journey to wellness!"
                    </p> */}
                </div> 
            </div>
        </div>
        <div onClick={navigateToServices}>
            <div className="image-text-section">
                <div className="image-container">
                    <div className="image-overlay">
                        <img 
                            src={fitness}
                            alt="fitness" 
                            className="image"
                        />
                    </div>
                </div>
                <div className="text-container">
                    <h2>Fitness Kickboxing</h2>
                    {/* <p>
                    "Find balance, flexibility, and inner peace through yoga. Rejuvenate your body and mind in every session. Join us to embark on your journey to wellness!"
                    </p> */}
                </div> 
            </div>
        </div>
        <div onClick={navigateToServices}>
            <div className="image-text-section">
                <div className="image-container">
                    <div className="image-overlay">
                        <img 
                            src={fullbody}
                            alt="fullbody" 
                            className="image"
                        />
                    </div>
                </div>
                <div className="text-container">
                    <h2>Full Body Circuit</h2>
                    {/* <p>
                    "Find balance, flexibility, and inner peace through yoga. Rejuvenate your body and mind in every session. Join us to embark on your journey to wellness!"
                    </p> */}
                </div> 
            </div>
        </div>
        {/* <div onClick={() => window.location.href = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ'}> 
            <div className="image-text-section">
            <div className="image-container">
                <div className="image-overlay">
                <img 
                    src={yoga2}
                    alt="Play Video" 
                    className="image"
                />
                </div>
            </div>
            <div className="text-container">
                <h2>Watch Video</h2>
                <p>
                "Click to watch our latest video and get inspired! Discover new techniques, tips, and workouts to enhance your fitness journey."
                </p>
            </div> 
            </div>
        </div> */}
       </div>
    )
};

export default HomeSections;
