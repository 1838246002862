import React, { useRef, useState, useEffect } from "react";
import './shared-components/dropin.css';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import moment from "moment";
import { useTranslation } from "react-i18next";
import PaymentDrawer from "../../shared-components/drawers/PaymentDrawer";
import Modal from "../../shared-components/modal/Modal";
import { useMount } from "react-use";
import bookingApi from '../../../api/bookingApi';
import { useRecoilState, useRecoilValue } from "recoil";
import {
  refreshTimeSlotsAtom,
  selectedDropInLocationAtom,
  selectedDropInDateAtom,
  isPaymentDrawerOpenAtom,
  selectedTimeSlotAtom,
  userSubscriptionsAtom
} from "../../../atoms/Atoms";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { userAtom } from "../../../atoms/Atoms";
import envConfig from "../../../envConfig";
import Loader from "../../shared-components/loader/Loader";

function DropIn() {
    const params = useParams();
    const { t, i18n } = useTranslation();
    const [activeSubscription, setActiveSubscription] = useState(false);
    const user = useRecoilValue(userAtom);
    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(false);
    const refreshTimeSlots = useRecoilValue(refreshTimeSlotsAtom);
    const [timeSlotDays, setTimeSlotDays] = useState([]);
    const [endDate, setEndDate] = useState(null);
    const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
    const [dateSelected, setDateSelected] = useState(false);
    const [hasData, setHasData] = useState(false);

    const [loadedSubscriptions, setLoadedSubscriptions] = useState(false);
    const [selectedLocation, setSelectedLocation] = useRecoilState(selectedDropInLocationAtom);
    const [isDrawerOpen, setIsDrawerOpen] = useRecoilState(isPaymentDrawerOpenAtom);
    const [selectedDate, setSelectedDate] = useRecoilState(selectedDropInDateAtom);
    const [selectedTimeSlot, setSelectedTimeSlot] = useRecoilState(selectedTimeSlotAtom);
    const [userSubscriptions, setUserSubscriptions] = useRecoilState(userSubscriptionsAtom);
    const [currentTimeSlotId, setCurrentTimeSlotId] = useState(null);
    const [loadingTimeSlots, setLoadingTimeSlots] = useState(false);
  
    const endDateOptions = [
      { title: `4 ${t('days')}`, date: moment().utc().add(4, 'day').format('YYYY-MM-DDTHH:00:00.00') },
      { title: t('today'), date: moment().utc().add(1, 'day').format('YYYY-MM-DDTHH:00:00.00') },
      { title: `3 ${t('days')}`, date: moment().utc().add(3, 'day').format('YYYY-MM-DDTHH:00:00.00') },
      { title: `5 ${t('days')}`, date: moment().utc().add(5, 'day').format('YYYY-MM-DDTHH:00:00.00') },
      { title: `7 ${t('days')}`, date: moment().utc().add(7, 'day').format('YYYY-MM-DDTHH:00:00.00') },
    ]

    const ref = useRef();
    const openDrawer = () => {
      setIsDrawerOpen(true);
    };
  
    const handleButtonClick = (i) => {
      setDateSelected(true);
    };

    //type 0=Rental; 1=Class
    function getLocations() {
      setLoading(true);
      bookingApi
        .get('events/locations?eventType=1')
          .then((response) => {
            setLoading(false);
            setLocations(_.sortBy(response.data.data, 'name'));
            if (response.data.data.length === 1) {
              setSelectedLocation(response.data.data[0]);
            }
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
          });
    }

    function getUserSubscriptions() {
      if(!user) return;
      setLoading(true);
      bookingApi
        .get(`subscriptions/${user.id}/active`)
        .then((response) => {
          setLoading(false);
          setUserSubscriptions(response.data.data);
          hasActiveSubscription(response.data.data);
          setLoadedSubscriptions(true);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }

    function hasActiveSubscription(subObj) {
      if(subObj?.length === 0) return false;
      else if (subObj?.length > 0) {
        return _.filter(subObj, (s) => s.isActive).length > 0;
      }
      else return false;
    }

    function searchTimeSlots() {
      if (!selectedLocation || !endDate || currentTimeSlotId) {
        return;
      }
      setTimeSlotDays([]);
      setLoadingTimeSlots(true);
      bookingApi
        .post('events/search/timeslots', {
          searchTerm: null,
          type: null,
          hostId: null,
          locationId: selectedLocation?.id,
          endDate: endDate?.date
        })
          .then((response) => {
            setLoadingTimeSlots(false);
            const tempDays = _.sortBy(response.data.data, 'startDate');
            setTimeSlotDays(tempDays);
           console.log('Time slots', response.data.data);
            setHasData(response.data.data === null ? false : true);
            if (selectedTimeSlot !== null && !currentTimeSlotId) {
              const filtered = _.filter(tempDays[selectedDate].timeSlots,
                (slot) => slot.id === selectedTimeSlot.id);
              setSelectedTimeSlot(filtered[0]);
            }
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
          });
    }

    function getSpacesLeft (timeSlot)
    {
      return timeSlot.maxCapacity - timeSlot.currentCapacity; 
    }

    function getTimeSlot() {
      bookingApi.get(`events/timeslot/${currentTimeSlotId}`)
        .then((response) => {
          if (response.data.success) {
            setIsDrawerOpen(true);
            setSelectedLocation(response.data.data.event.location);
            setSelectedTimeSlot(response.data.data);
            setSelectedDate(response.data.data.startTime);
            setTimeout(() => setCurrentTimeSlotId(null), 3000);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    useEffect(() => {
      if (selectedLocation) {
        // console.log('searching');
        // console.log('selectedLocation', selectedLocation);
        searchTimeSlots();
        if (!currentTimeSlotId) {
          setSelectedDate(null);
          setIsDrawerOpen(false);
        }
      }
    }, [selectedLocation, endDate]);
  
    useMount(() => {
      console.log('mounting');
      setEndDate(endDateOptions[4]);
      getLocations();
      setSelectedDate(null);
      setTimeSlotDays([]);
    }, []);

    useEffect(() => {
      if (user) {
        getUserSubscriptions();
      }
    }, [user]);

    useEffect(() => {
      if (!isDrawerOpen) {
        getUserSubscriptions();
      }
    }, [isDrawerOpen]);
  
    useEffect(() => {
      if (selectedLocation) {
        searchTimeSlots();
      }
      if (!currentTimeSlotId) {
        setIsDrawerOpen(false);
      }
    }, [selectedLocation, endDate]);

    useEffect(() => {
      searchTimeSlots();
    }, [refreshTimeSlots]);
    
    useEffect(() => {
      if (params?.timeSlotId && loadedSubscriptions && hasData) {
        setCurrentTimeSlotId(params?.timeSlotId);
      }
    }, [params, loadedSubscriptions, hasData]);

    useEffect(() => {
      if (currentTimeSlotId) {
        getTimeSlot();
      }
    }, [currentTimeSlotId]);

    const buttonVariants = {
        initial: { y: 1000, opacity: 0 }, // Initial position (from the right) and invisible
        animate: { y: 0, opacity: 1 }, // Animate to the original position and visible
      };
      const hourVariants = {
        initial: { x: 1000, opacity: 0 }, // Initial position (from the right) and invisible
        animate: { x: 0, opacity: 1 }, // Animate to the original position and visible
        exit: {x: 1000, opacity: 0}
      };
      const sideVariants = {
        initial: { x: 1000, opacity: 0 }, // Initial position (from the right) and invisible
        animate: { x: 0, opacity: 1 }, // Animate to the original position and visible
        exit: {x: 1000, opacity: 0}
      };
    return <div className="body">
        <div className="dropin-background-image"></div>
        <div className="gradient-overlay"></div> {/* Add the gradient overlay */}
          <PaymentDrawer />
      {/* ... (your existing code) */}
        <div>           
            <div className="center">
                <div className="messageContainer">
                    <div className="diheaderText text-center">Crosstrain</div>
                    <div className="disubheaderText text-center">Join any of our available classes.</div>
                    <div className="disubheaderText text-center">Secure your spot by booking ahead of class.</div>
                    
                    {hasData && <div>
                        <div className="diregularText text-center hidden">The first step is always the hardest.</div>
                        <div className="dilinkText text-center text-yellow-300">Our sessions last 60 minutes and are designed to be scalable for all fitness levels.</div>
                        </div>}
                    {!hasData && <div className="dilinkText text-center">There are no active classes to drop into right now. Tray again later.</div>}
                    {user && <div className="canlinkText text-center">
                      You may cancel reserved class from the reservations section in your <a href="/profile/reservations" className="profile-link">profile</a>.
                    </div>}
                </div>
                <div className="listsContainer">
                <div className="flex flex-col">
                  <div className="mb-4">
                  {locations && locations.length > 0 && <div className="block text-center px-10 mb-2 text-yellow-400 hidden">Choose a location</div>}
                  {locations && _.map(locations, (loc, index) => (
                   <div className="hidden" key={`${loc.id}-loc`}>
                      <motion.button
                        key={`${index}`}
                        initial="initial" // Use the "initial" variant
                        animate="animate" // Use the "animate" variant
                        variants={buttonVariants} // Define the animation variants
                        transition={{ delay: index * 0.2 }} // Apply a delay based on the index
                        onClick={() => 
                          setSelectedLocation(loc)}
                        className={`${selectedLocation && (selectedLocation.id=== loc.id) ? 'border-2 ring-1 ring-offset-1 ring-white border-white' : null} red-button`}
                    >   {loc.nameEs}
                    </motion.button>
                  </div>))}
                </div>
                    <div>
                <AnimatePresence key="dropindays">
                  <div className="mx-0 mt-2 mb-2 w-full text-center">{selectedLocation && timeSlotDays?.length > 0 && 'Available Dates'}</div>
                  <div className="mx-0 mt-2 mb-2 w-full text-center">
                    {loadingTimeSlots && (
                      <div className="flex flex-col items-center">
                        <div className="mb-2"><Loader /></div>
                        <div>Loading Dates...</div>
                      </div>
                    )}
                  </div>
                  <div className="mx-0 mt-2 mb-2 w-full text-center">{!loadingTimeSlots && selectedLocation && timeSlotDays?.length === 0 && 'No hours available'}</div>
                    {_.map(timeSlotDays, (day, index) => (
                   <div key={`${index}-day-button`}>
                    <motion.button
                        key={`${index}-day-button`}
                        disabled={!selectedLocation}
                        initial="initial" // Use the "initial" variant
                        animate="animate" // Use the "animate" variant
                        variants={buttonVariants} // Define the animation variants
                        transition={{ delay: index * 0.2 }} // Apply a delay based on the index
                        className={`${selectedDate && (selectedButtonIndex === index) ? 'border-2 border-white' : null} dropin-day-button`}
                        onClick={() => 
                          { setSelectedDate(timeSlotDays[index]);
                            setSelectedButtonIndex(index);
                            setDateSelected(day);
                            setIsDrawerOpen(false);
                          }
                        }
                    >   {moment(day?.startDate).format('dddd, MMMM DD, YYYY')}
                    </motion.button>
                    <div className="mx-0 mt-2 mb-2 w-full text-center">{loadingTimeSlots && 'Loading sessions...'}</div>
                    <div className="mx-0 mt-2 mb-2 w-full text-center">{!loadingTimeSlots && selectedLocation && selectedDate?.timeSlots?.length === 0 && 'No Hours available'}</div>
                    {selectedButtonIndex === index && selectedDate && _.map(_.sortBy(selectedDate.timeSlots, 'startTime'), (timeSlot, tsIndex) => (
                    <motion.button
                          key={`${timeSlot.id}-${tsIndex}-inner`}
                          initial="initial" // Use the "initial" variant
                          animate="animate" // Use the "animate" variant
                          exit="exit"
                          disabled={timeSlot && timeSlot.isFull}
                          variants={hourVariants} // Define the animation variants
                          transition={{ delay: tsIndex * 0.1 }} // Apply a delay based on the index
                          className={`${!timeSlot.isFull ? 'dropin-second-button' :  'full-class'} block md:hidden w-5/6'`}
                          onClick={ () => {
                            if(timeSlot && timeSlot.reservedByUser)
                            {
                                return;
                            }
                            else {
                            setSelectedTimeSlot(timeSlot);
                            openDrawer();
                            }
                          }}
                      >   
                        {timeSlot && timeSlot.isFull && <div>
                        {`Full Class (${moment(timeSlot?.startTime).add(-4,'hour').format('h:mm A')})`}
                        </div>}
                        {timeSlot && timeSlot.reservedByUser && !timeSlot.isFull && 
                        <div className="text-yellow-400 flex flex-row">
                           <CheckCircleIcon className="h-6 w-6 mx-2" aria-hidden="true" />
                        {`Checked-In (${moment(timeSlot?.startTime).add(-4,'hour').format('h:mm A')})`}
                        </div>}
                       {timeSlot && !timeSlot.reservedByUser && !timeSlot.isFull && `${moment(timeSlot?.startTime).add(-4,'hour').format('h:mm A')} (${getSpacesLeft(timeSlot)} spaces)`}
                      </motion.button>
                    ))}
                    </div>
                    ))}
                </AnimatePresence>
                </div>
                </div>  
                <div className="sideList">
                <AnimatePresence key="dropintimeslots">
                  <div className="mx-0 mt-2 w-full text-center">{selectedDate && 'Available Hours'}</div>
                  {selectedDate && _.map(_.sortBy(selectedDate.timeSlots, 'startTime'), (timeSlot, tsIndex) => (
                    <motion.button
                          key={`${timeSlot.id}-${tsIndex}-side`}
                          initial="initial" // Use the "initial" variant
                          animate="animate" // Use the "animate" variant
                          exit="exit"
                          disabled={timeSlot && timeSlot.isFull}
                          variants={sideVariants} // Define the animation variants
                          transition={{ delay: tsIndex * 0.1 }} // Apply a delay based on the index
                          className="dropin-second-button hidden md:block"
                          onClick={ () => {
                            if(timeSlot && timeSlot.reservedByUser)
                            {

                                return;
                            }
                            else {
                            setSelectedTimeSlot(timeSlot);
                            openDrawer();
                            }
                          }}
                      >
                        {timeSlot && timeSlot.isFull && <div>
                        {`Full Class (${moment(timeSlot?.startTime).add(-4,'hour').format('h:mm A')})`}
                        </div>}
                       {timeSlot && timeSlot.reservedByUser && !timeSlot.isFull && 
                        <div className="text-yellow-400 flex flex-row">
                           <CheckCircleIcon className="h-6 w-6 mx-2" aria-hidden="true" />
                        {`Checked-In (${moment(timeSlot?.startTime).add(-4,'hour').format('h:mm A')})`}
                        </div>}
                        {timeSlot && !timeSlot.reservedByUser && !timeSlot.isFull && `${moment(timeSlot?.startTime).add(-4,'hour').format('h:mm A')} (${getSpacesLeft(timeSlot)} spaces)`}
                      </motion.button>
                    ))}
              </AnimatePresence>
              </div>   
              </div>     
            </div>
        </div>
    </div>
}

export default DropIn;