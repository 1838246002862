import React from 'react';
import { useTranslation } from 'react-i18next';
import { XMarkIcon } from '@heroicons/react/24/outline'

function TermsAcceptanceDrawer({ isOpen, onClose, onCancelTerms }) {

    const { t } = useTranslation();
  return (
    <div className={`overflow-y-scroll terms-drawer ${isOpen ? 'open' : ''}`}>        
      <div className="terms-drawer-content">
      <div>
        <button 
        className="cancel-button"
        onClick={ () => {
            onCancelTerms();
        }}>
        <div className="flex flex-row">
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          <div className="ml-2">Cancel</div>
        </div>
      </button>
      </div>
      <h2 className="terms-header mt-12">Terms and Conditions</h2>
        <p className="terms-subheader"> 
          Please read the following terms carefully. Scroll to en to accept...
        </p>
        <br/>
        <p className="terms-text">
          {t('tos_content_1')}
        </p>
        <br/>
        <p className="terms-text">
          {t('tos_content_2')}
        </p>
        <br/>
        <p className="terms-text">
          {t('tos_content_3')}
        </p>
        <br/>
        <p className="terms-text">
          {t('tos_content_4')}
        </p>
        <p className="terms-text">
          {t('tos_content_5')}
        </p>
        <button className="accept-terms-button" onClick={onClose}>Accept Terms</button>
      </div>
    </div>
  );
}

export default TermsAcceptanceDrawer;