const envConfig = {
  API_KEY: process.env.REACT_APP_API_KEY ?? 'ec1c559c517d42fd8034d91c7ce7314f',
  API_URL: process.env.REACT_APP_API_URL ?? 'https://fitbox-grocefy-api-c6hyb2bac6ecdjag.eastus2-01.azurewebsites.net/api',
  APP_UTILITY_KEY: process.env.REACT_APP_UTILITY_KEY ?? 'P0lqsTB37qXO0jX6',
  BOOKING_API_URL: process.env.REACT_APP_BOOKING_API_URL ?? 'https://fitbox-booking-api-fzfbefexhxgyhzh4.eastus2-01.azurewebsites.net/api/',
  BOOKING_API_KEY: process.env.REACT_APP_BOOKING_API_KEY ?? '#{REACT_APP_BOOKING_API_KEY}#',
  HUB_URL: process.env.REACT_APP_HUB_URL ?? 'https://fitbox-grocefy-api-c6hyb2bac6ecdjag.eastus2-01.azurewebsites.net',
  PaypalClientId: process.env.REACT_APP_PAYPAL_CLIENTID ?? '#{REACT_APP_PAYPAL_CLIENTID}#',
  GEO_API_KEY: process.env.REACT_APP_GEO_API_KEY ?? 'AIzaSyB6jl09IPMyXwc55wjIj40Mi9p2ABtZKf0',
  SearchItemsPageSize: process.env.REACT_APP_SEARCH_PAGE_SIZE ?? '#{REACT_APP_SEARCH_PAGE_SIZE}#',
  StorageKey: process.env.REACT_APP_STORAGE_KEY ?? 'fitbx', 
  Prefix: process.env.REACT_APP_PREFIX ?? '#{REACT_APP_PREFIX}#', 
  DefaultLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE ?? 'en', 
  TemplateStyle: process.env.REACT_APP_TEMPLATE_STYLE ?? '#{REACT_APP_TEMPLATE_STYLE}#',
  TemplateVersion: process.env.REACT_APP_TEMPLATE_VERSION ?? '10',
  HeaderVersion: process.env.REACT_APP_HEADER_VERSION ?? '#{REACT_APP_HEADER_VERSION}#',
  SubHeaderVersion: process.env.REACT_APP_SUBHEADER_VERSION ?? '#{REACT_APP_SUBHEADER_VERSION}#',
  StoreVersion: process.env.REACT_APP_STORE_VERSION ?? '10',
  WelcomeLong: process.env.REACT_APP_WELCOME_LONG ?? '#{REACT_APP_WELCOME_LONG}#',
  WelcomeShort: process.env.REACT_APP_WELCOME_SHORT ?? '#{REACT_APP_WELCOME_SHORT}#',
  DepartmentsStyle: process.env.REACT_APP_DEPARTMENTS_STYLE ?? 'sidebar-right',
  DepartmentCarouselSize: 6,
  CardBorderStyle: process.env.REACT_APP_CARD_BORDER_STYLE ?? '#{REACT_APP_CARD_BORDER_STYLE}#',
  ButtonStyle: process.env.REACT_APP_BUTTON_STYLE ?? '#{REACT_APP_BUTTON_STYLE}#',
  SocialFooterVersion: process.env.REACT_APP_SOCIAL_FOOTER_VERSION ?? '#{REACT_APP_SOCIAL_FOOTER_VERSION}#',
  InstagramLink: process.env.REACT_APP_SOCIAL_INSTAGRAM ?? '#{REACT_APP_SOCIAL_INSTAGRAM}#',
  FacebookLink: process.env.REACT_APP_SOCIAL_FACEBOOK ?? '#{REACT_APP_SOCIAL_FACEBOOK}#',
  WelcomeVersion: process.env.REACT_APP_WELCOME_VERSION ?? '#{REACT_APP_WELCOME_VERSION}#',
  PayPalForBookings: process.env.REACT_APP_PAYPAL_BOOKING ?? 'true',
  AthMovilForBookings: process.env.REACT_APP_ATHMOVIL_BOOKING ?? '#{REACT_APP_ATHMOVIL_BOOKING}#',
  AthMovilPublicKey: process.env.REACT_APP_ATHMOVIL_PUBLIC_KEY ?? '#{REACT_APP_ATHMOVIL_PUBLIC_KEY}#',
  AthMovilPrivateKey: process.env.REACT_APP_ATHMOVIL_PRIVATE_KEY ?? '#{REACT_APP_ATHMOVIL_PRIVATE_KEY}#',
  PayPalKey: process.env.REACT_APP_PAYPAL_KEY ?? '#{REACT_APP_PAYPAL_KEY}#',
  AllowCreditCardCreate: process.env.REACT_APP_ALLOW_CARD_CREATE ?? 'false',
  ShowPaymentButtonOnBookingReservation: process.env.REACT_APP_SHOW_PAYMENT_ON_BOOKING ?? '#{REACT_APP_SHOW_PAYMENT_ON_BOOKING}#',
  ShowButtonOnBookingReservation: process.env.REACT_APP_SHOW__ON_BOOKING ?? '#{REACT_APP_SHOW__ON_BOOKING}#',
  FacebookPixelId: process.env.REACT_APP_FACEBOOK_PIXEL_ID ?? '#{REACT_APP_FACEBOOK_PIXEL_ID}#',
  GoogleAnalyticsTrackingId: process.env.REACT_APP_GA_TRACKING_ID ?? '#{REACT_APP_GA_TRACKING_ID}#',
  BrainTreeTokenizationKey: process.env.REACT_APP_BRAINTREE_TOKENIZATION_KEY ?? '#{REACT_APP_BRAINTREE_TOKENIZATION_KEY}#',
  ContactEmail: process.env.REACT_APP_CONTACT_EMAIL ?? 'temporary@gopanza.com',
  JobsEmail: process.env.REACT_APP_JOBS_EMAIL ?? 'temporary@gopanza.com',
  HasReferrals: process.env.REACT_APP_HAS_REFERRALS_ENABLED ?? '#{REACT_APP_HAS_REFERRALS_ENABLED}#',
  CardVersion: process.env.REACT_APP_CARD_VERSION ?? '10',
  SidebarVersion: process.env.REACT_APP_SIDEBAR_VERSION ?? '10',
  WebstoreId: process.env.REACT_APP_WEDSTORE_ID ?? '10',
  AllowCreditCardsInBooking: process.env.REACT_APP_CREDITCARD_BOOKING ?? '#{REACT_APP_CREDITCARD_BOOKING}#',
  AllowCreditCardsInWebstore: process.env.REACT_APP_CREDITCARD_WEBSTORE ?? '#{REACT_APP_CREDITCARD_WEBSTORE}#',
  CounterVersion: process.env.REACT_APP_COUNTER_VERSION ?? '#{REACT_APP_COUNTER_VERSION}#',
  ShowWebstore: process.env.REACT_APP_SHOW_WEBSTORE ?? '#{REACT_APP_SHOW_WEBSTORE}#',
  PlaceToPayUrl: process.env.REACT_APP_PLACETOPAY_URL ?? 'https://checkout-test.placetopay.com/',
  PlaceToPayLogin: process.env.REACT_APP_PLACETOPAY_LOGIN ?? 'dfd1ae31deb9139f18b0f9c57ff8a6be',
  PlaceToPaySecret: process.env.REACT_APP_PLACETOPAY_SECRET ?? '#{REACT_APP_PLACETOPAY_SECRET}#'
};

export default envConfig;
