import axios from 'axios';
import envConfig from '../envConfig';

const api = axios.create({
  baseURL: envConfig.PlaceToPayUrl,
});
api.interceptors.request.use(
  (request) => {
    request.headers['Accept-Language'] = `${window.localStorage.getItem(`${envConfig.StorageKey}-lang`)}-US`.replaceAll('"', '');
    return request;
  }
);

export default api;
