import React, { useEffect, useRef, useState } from "react";
import './shared-components/subscription.css';
import { motion, AnimatePresence } from 'framer-motion';
import _, { add } from 'lodash';
import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";
import Logo from "../../shared-components/logo/Logo";
import SubscriptionDrawer from "../../shared-components/drawers/SubscriptionDrawer";
import bookingApi from '../../../api/bookingApi';
import { useMount } from "react-use";
import Modal from "../../shared-components/modal/Modal";
import '../../../assets/css/custom.css'
import { userAtom } from "../../../atoms/Atoms";
import { useRecoilValue } from "recoil";
import { getDateExtensionType, getRecurrence, getRecurrenceLength, isSameDay, toLocalTime } from "../../../functions";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { hasActiveSubscription } from "../../../functions";


function Subscription() {
  const { t } = useTranslation();
  const user = useRecoilValue(userAtom);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const ref = useRef();
  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };
    const [planSelected, setPlanSelected] = useState(null);
    const [loading, setLoading] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [userSubscriptions, setUserSubscriptions] = useState([]);
    const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
    const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [locations, setLocations] = useState([]);
    const buttonVariants = {
        initial: { y: 1000, opacity: 0 }, // Initial position (from the right) and invisible
        animate: { y: 0, opacity: 1 }, // Animate to the original position and visible
        exit: { y: 1000, opacity: 0 }
      };

      function getSubscriptions() {
        setLoading(true);
        bookingApi
          .get('subscriptions/active?includeBenefits=true')
          .then((response) => {
            console.log('subscriptions', response.data.data);
            setLoading(false);
            setSubscriptions(response.data.data);
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
          });
      }

      function getUserSubscriptions() {
        if(!user) return;
        setLoading(true);
        bookingApi
          .get(`subscriptions/${user.id}/active`)
          .then((response) => {
            setLoading(false);
            setUserSubscriptions(response.data.data);
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
          });
      }

      useMount(() => {
        getSubscriptions();
      });

      useEffect(() => {
        getSubscriptions();
      },[user]);

      useEffect(() => {
          getUserSubscriptions();
      }, []);


  
      const bVariants = {
        initial: { x: 1000, opacity: 0 }, // Initial position (from the right) and invisible
        animate: { x: 0, opacity: 1 }, // Animate to the original position and visible
      };

      const handleButtonClick = (index) => {
        setSelectedButtonIndex(index);
        setPlanSelected(subscriptions[index]);
        openDrawer();
      };
    
      
    return <div className="body">
       <div className="memberships-backgroundImage"></div>
        <AnimatePresence key="memberships">
          <motion.div
            key="motion-div-memberships"
            initial={{ opacity: 0, y: 100 }}  // Start from below with opacity 0
            animate={{ opacity: 1, y: 0, transition: { duration: 1.0 } }}  // Move to original position with full opacity
          >
            <Logo />
            <div className="gradient-overlay"></div> {/* Add the gradient overlay */}
            <SubscriptionDrawer isOpen={isDrawerOpen} selectedSubscription={planSelected} selectedLocation={selectedLocation} closeDrawer={closeDrawer} />
            {/* Use the Modal component when the drawer is open */}
            <Modal isOpen={isDrawerOpen} closeModal={closeDrawer}>
            {/* Place the content of the modal here */}
            <h2>Modal Content</h2>
            {/* Add any other content or components you want */}
            </Modal>
            <div className="center">
                <div className="messageContainer">
                    <div key="header" className="headerText mt-20 md:mt-2">Membership(s)</div>
                    <div key="subheader" className="subheaderText">"Train hard, fight easy." </div>
                    <div key="subheader2" className="subheaderText">– Muhammad Ali</div>
                {/* {!user && 
                <div className="mt-24 px-6">Please <a className="text-yellow-500" href="/login">login</a> to view our plans</div>} */}
                <div>
                <div className="listsContainer mt-8">
                {subscriptions && subscriptions.length === 0 &&  <div className="flex content-center text-center w-full mx-12 mb-2 text-red-600">No memberships are available at this moment</div>}
                  {subscriptions && subscriptions.length > 0 && <div className="md:hidden block text-center px-2 mb-2 text-white">Our Plans</div>}
                    {_.map(subscriptions, (sub, index2) => (
                  <div>
                   <motion.button
                        key={`k-${index2}`}
                        initial="initial" // Use the "initial" variant  
                        animate="animate" // Use the "animate" variant
                        variants={buttonVariants} // Define the animation variants
                        transition={{ delay: index2 * 0.5 }} // Apply a delay based on the index
                        onClick={() => handleButtonClick(index2)}
                    >
                        <div key={`s-${index2}`} className={sub.isOnPromo ? 'specials-button' : 'subscription-action-button'}>
                            <div className={`${sub.isOnPromo ? 'block font-semibold' : 'hidden'}`}>{t('special_offer')}</div>
                            {sub.isOnPromo && (
                              <div className="mb-2">
                                <FontAwesomeIcon className="mr-2" icon={faClock} />
                                {isSameDay(toLocalTime(sub.promoEndDate), toLocalTime(dayjs()).startOf('date')) ? t('ends_today') : `${t('ends_on')} ${toLocalTime(sub.promoEndDate).format("MMM DD, YYYY")}`}
                              </div>
                            )}
                            <div key={`sub-${index2}`} className="font-bold text-xl">{sub.nameEn}</div>
                            <div key={`des-${index2}`} className="text-sm">{sub.descriptionEn}</div>
                            <div className={sub.isOnPromo && sub.promoPrice ? 'line-through' : ''} key={`price1-${index2}`}>
                              {sub.recurrence === 0 && `$${sub.price?.toFixed(2)}`}
                              {sub.recurrence > 0 && `$${sub.price?.toFixed(2)}/${getRecurrenceLength(sub.recurrence)}`}
                            </div>
                            {sub.isOnPromo && sub.promoPrice && sub.recurrence > 0 && (
                              <div className="font-bold text-xl" key={`price2-${index2}`}>
                                {sub.recurrence === 0 && `$${sub.promoPrice?.toFixed(2)}`}
                                {sub.recurrence > 0 && `$${sub.promoPrice?.toFixed(2)}/${getRecurrenceLength(sub.recurrence)}`}
                              </div>
                            )}
                             {sub.isOnPromo && sub.promoPrice && sub.recurrence === 0 && (
                              <div className="font-bold text-xl" key={`price2-${index2}`}>
                                {sub.recurrence === 0 && `$${sub.promoPrice?.toFixed(2)}`}
                                {sub.recurrence > 0 && `$${sub.promoPrice?.toFixed(2)}}`}
                              </div>
                            )}
                            {sub.isOnPromo && sub.recurrence > 0 && sub.dateExtensionType > 0 && (
                              // <div className="font-bold" key={`dateext-${index2}`}>+{sub.dateExtensionValue} {t(getDateExtensionType(sub.dateExtensionType))} {t('free')}</div>
                              <div className="font-bold" key={`dateext-${index2}`}>{sub.dateExtensionValue} {t(getDateExtensionType(sub.dateExtensionType))}</div>
                            )}
                        </div>
                    </motion.button>
                      </div>
                    ))}
                </div>     
                </div>        
                </div>
            </div>
      </motion.div>
      </AnimatePresence>
    </div>
}

export default Subscription;
