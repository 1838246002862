import React, { useEffect, useState } from 'react';
import './shared-components/Drawer.css';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { useRecoilState, useRecoilValue } from "recoil";
import { XMarkIcon } from '@heroicons/react/24/outline'
import { faCancel, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notificationsAtom, userAtom, changeSubscriptionPaymentMethodAtom } from '../../../atoms/Atoms';
import Loader from '../loader/Loader';
import api from '../../../api/api';
import PlaceToPayLogo from '../../../assets/images/logo/placetopay-logo-dark.svg';

function ChangeSubscriptionCard() {
  const user = useRecoilValue(userAtom);
  const { t, i18n } = useTranslation();
  const [changeSubscriptionPaymentMethod, setChangeSubscriptionPaymentMethod] = useRecoilState(changeSubscriptionPaymentMethodAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);

  function getCards() {
    setCards([]);
    setLoading(true);
    api
      .get('users/me/cards')
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          setCards(response.data.data.results);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.message);
      });
  }

  useEffect(() => {
    if (changeSubscriptionPaymentMethod.drawer) {
      getCards();
    }
  }, [changeSubscriptionPaymentMethod.drawer]);

  return (
    <div className={`drawer ${changeSubscriptionPaymentMethod.drawer ? 'open' : ''}`}>
      <button 
        className="close-button"
        onClick={ () => {
          setChangeSubscriptionPaymentMethod({
            drawer: false,
            selectedCardId: null,
            selectedSubscription: null,
            update: false
          });
        }}>
        <div className="flex flex-row">
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          <div className="ml-2">Close</div>
        </div>
      </button>
      <div className="drawer-content">
        {loading && (
          <div className="flex mt-12 justify-center">
            <Loader color="bg-white" />
          </div>
        )}
        {!loading && (
          <div className="space-y-4">
            <div className="flex text-center">
              Select a payment method to attach to your subscription
            </div>  
            {_.map(cards, (card) => (
              <button
                disabled={loading}
                key={card.id}
                type="button"
                onClick={() => {
                  setChangeSubscriptionPaymentMethod({
                    ...changeSubscriptionPaymentMethod,
                    drawer: false,
                    selectedCardId: card.id,
                    update: true
                  });
                }}
                className={`w-full px-4 ${loading ? 'opacity-75 cursor-not-allowed' : ''}`}
              >
                <div className="flex shadow rounded-xl flex h-20 transition duration-500 ease-linear ring-2 ring-offset-2 ring-purple-400 border border-purple-400 outline-none focus:outline-none">
                  <div className="w-16 flex flex-col items-center justify-center text-white text-3xl bg-purple-600 rounded-l-xl">
                    <FontAwesomeIcon icon={faCreditCard} />
                  </div>
                  <div className="flex flex-col flex-1 justify-center text-xs p-2">
                    <div className="font-semibold w-full">{card.brand}</div>
                    <div>**** {card.last4}</div>
                    <div>{card.expirationDate}</div>
                  </div>
                  <div className="flex">
                    {card.paymentType === 26 && (
                      <img
                        className="w-24 mr-2"
                        alt="Evertec Place To Pay logo"
                        src={PlaceToPayLogo}
                      />
                    )}
                  </div>
                </div>
              </button>
            ))}
            <button
              type="button"
              disabled={loading}
              onClick={() => {
                setChangeSubscriptionPaymentMethod({
                  ...changeSubscriptionPaymentMethod,
                  drawer: false,
                  selectedCardId: null,
                  update: true
                });
              }}
              className={`w-full px-4 ${loading ? 'opacity-75 cursor-not-allowed' : ''}`}
            >
              <div className="flex shadow rounded-xl flex h-20 transition duration-500 ease-linear ring-2 ring-offset-2 ring-red-400 border border-red-400 outline-none focus:outline-none">
                <div className="w-16 h-full flex flex-shrink-0 items-center justify-center text-white text-3xl bg-red-400 rounded-l-xl">
                  <FontAwesomeIcon icon={faCancel} />
                </div>
                <div className="flex flex-col justify-center p-4 text-xs">
                  {/* <div className="font-semibold">{t('add_new_payment')}</div> */}
                  <div>Disable Auto-Renew</div>
                </div>
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ChangeSubscriptionCard;
