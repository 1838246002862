import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from 'recoil';
import EventCard from "./EventCard";
import "./EventGrid.css"; // External CSS for grid layout
import dayjs from 'dayjs';
import yoga from "../../../../assets/images/stock/yoga.png";
import legs from "../../../../assets/images/stock/legs.png";
import massage from "../../../../assets/images/stock/massage.png";
import running from "../../../../assets/images/stock/running.jpg";  
import ivdrip from "../../../../assets/images/stock/iv_bw_resized.png";
import hitcamp from "../../../../assets/images/stock/hitcamp.png";
import bookingApi from "../../../../api/bookingApi"
import _ from 'lodash';
import { useTranslation } from 'react-i18next';



function EventGrid() {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);


  function searchAllEvents() {
    setLoading(true);
    bookingApi
    .get('events/all')
      .then((response) => {
        setLoading(false);
        setEvents(_.sortBy(response.data.data.filter(item => item.isActive), 'name'));
        if (response.data.data.length > 0) {
          setSelectedEvent(_.sortBy(response.data.data, 'name')[0]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }
  
  useEffect(() => {
    searchAllEvents();
  }, []);

  return (
    <div className="event-grid my-8">     
    {_.map(events, (e, index) => (  
        <EventCard
        key={e.id}
        imageSrc={e.imageUrl}
        eventName={e.nameEn}
        eventId={e.id}
      />
    ))}
    </div>
  );
}

export default EventGrid;
