import React from "react";
import EventGrid from "./shared-components/EventGrid";
import "./services.css"; // External CSS for grid layout    


function Services() {
  return (
    <div className="services-page-container">
        <h1 className="services-header">Services</h1>
        <EventGrid />
    </div>
  );
}

export default Services;
