// Logo.js
import React from 'react';
import logo from '../../../assets/images/logo/logo_white.png';
import './shared-components/logo.css';
import { NavLink } from 'react-router-dom';

const Logo = () => {
  return (
    <div className="logobody">
      <NavLink
        to="/">
           <img src={logo} className="logo" alt="Fit Boxing Logo" />
          <div className="logoprompt">
                <div></div>
          </div>
      </NavLink>     
    </div>

  );
}

export default Logo;
